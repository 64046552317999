import axios from 'axios';
import {
  EventBus
} from './event-bus.js';

export class APIService {

  constructor(endpoint) {
    this.endpoint = endpoint;
  }

  _checkTokenValidity(promise) {
    promise.catch((err) => {
      if (err.response.status == 401) {
        EventBus.$emit('delete-session');
      }
    });
    return promise;
  }

  getConfig(noCache = false) {
    let headers = {
      'X-Access-Token': localStorage.getItem("jwt")
    };
    if (noCache) {
      headers.Pragma = 'no-cache';
    }
    return {
      headers
    };
  }

  getAll(filterString) {
    var url = `${process.env.VUE_APP_API_URL}/${this.endpoint}`;
    if (filterString != null && filterString != "") {
      url += "?" + filterString;
    }
    return this._checkTokenValidity(axios.get(url, this.getConfig(true))).then(response => {
      return response.data;
    });
  }

  getOne(id) {
    const url = `${process.env.VUE_APP_API_URL}/${this.endpoint}/${id}`;
    return this._checkTokenValidity(axios.get(url, this.getConfig(true))).then(response => response.data);
  }

  execCustomGet(urlExt) {
    const url = `${process.env.VUE_APP_API_URL}/${this.endpoint}/${urlExt}`;
    return this._checkTokenValidity(axios.get(url, this.getConfig(true))).then(response => response.data);
  }

  insert(entity) {
    const url = `${process.env.VUE_APP_API_URL}/${this.endpoint}`;
    return this._checkTokenValidity(axios.post(url, entity, this.getConfig()));
  }

  replaceOrInsert(entity) {
    const url = `${process.env.VUE_APP_API_URL}/${this.endpoint}`;
    return this._checkTokenValidity(axios.put(url, entity, this.getConfig()));
  }

  update(entity) {

    const url = `${process.env.VUE_APP_API_URL}/${this.endpoint}/${entity.id}`;
    return this._checkTokenValidity(axios.put(url, entity, this.getConfig()));
  }

  patchAttribute(id, attributes) {
    const url = `${process.env.VUE_APP_API_URL}/${this.endpoint}/${id}`;
    return this._checkTokenValidity(axios.patch(url, attributes, this.getConfig()));
  }

  delete(id) {
    const url = `${process.env.VUE_APP_API_URL}/${this.endpoint}/${id}`;
    return this._checkTokenValidity(axios.delete(url, this.getConfig()));
  }

  uploadFiles(container, formData) {
    return this._checkTokenValidity(axios({
      url: `${process.env.VUE_APP_API_URL}/${this.endpoint}/${container}/upload`,
      method: 'POST',
      data: formData,
      headers: {
        'X-Access-Token': localStorage.getItem("jwt"),
        'Content-Type': 'multipart/form-data'
      }
    }));
  }

  deleteFile(container, file) {
    return this._checkTokenValidity(axios.delete(`${process.env.VUE_APP_API_URL}/${this.endpoint}/${container}/files/${file}`, this.getConfig()));
  }

  // backendUser endpoint
  login(username, password) {
    return axios.post(`${process.env.VUE_APP_API_URL}/${this.endpoint}/login`, {
      username: username,
      password: password
    });
  }

  logout() {
    return this._checkTokenValidity(axios.post(`${process.env.VUE_APP_API_URL}/${this.endpoint}/logout`, this.getConfig()));
  }
  syncAll() {
    return this._checkTokenValidity(axios.get(`${process.env.VUE_APP_API_URL}/${this.endpoint}/sync-all`,  this.getConfig()));
  }

  resetPasword(password, token) {
    return axios.post(`${process.env.VUE_APP_API_URL}/${this.endpoint}/reset-password/?access_token=${token}`, {
      newPassword: password
    });
  }

  changePasword(oldPassword, newPassword) {
    return axios.post(`${process.env.VUE_APP_API_URL}/${this.endpoint}/change-password/`, {
      newPassword: newPassword,
      oldPassword: oldPassword
    }, this.getConfig());
  }

  sendVerificationEmail(id) {
    return axios.post(`${process.env.VUE_APP_API_URL}/${this.endpoint}/${id}/verify`);
  }

  sendPasswordResetEmail(email) {
    return axios.post(`${process.env.VUE_APP_API_URL}/${this.endpoint}/reset`, {
      email: email
    });
  }
}

APIService.syncCount = 0;
