
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import {
  SecurityService
} from './security-service';
import VueGoodWizard from 'vue-good-wizard';
import BootstrapVue from 'bootstrap-vue';
import VeeValidate, {
  Validator
} from 'vee-validate';
import de from 'vee-validate/dist/locale/de';

Vue.use(VeeValidate, {
  fieldsBagName: 'veeFields'
});
Validator.localize('de', de);

Vue.use(BootstrapVue);
Vue.use(VueGoodWizard);

Vue.config.productionTip = false;
const securityService = new SecurityService();

Vue.mixin({
  data() {
    return {
      securityService: null
    }
  },
  created() {
    this.securityService = securityService;
  }
});

if (!String.prototype.endsWith) {
  String.prototype.endsWith = function(search, this_len) {
    if (this_len === undefined || this_len > this.length) {
      this_len = this.length;
    }
    return this.substring(this_len - search.length, this_len) === search;
  };
}

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('jwt') == null) {
      next({
        path: '/login',
        params: {
          nextUrl: to.fullPath
        }
      });
    } else {
      if (to.matched.some(record => record.meta.isAdmin)) {
        if (securityService.isCurrentAdmin()) {
          next();
        } else {
          router.replace(from.path);
        }
      } else if (to.matched.some(record => record.meta.isEventUser)) {
        if (securityService.isCurrentEventUser()) {
          next();
        } else {
          router.replace(from.path);
        }
      } else {
        next();
      }
    }
  } else if (to.matched.some(record => record.meta.guest)) {
    if (localStorage.getItem('jwt') == null) {
      next();
    } else {
      next({
        name: '/Home'
      });
    }
  } else if (to.path == '/') {
    next('/Home');
  } else {
    next();
  }
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
