import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode:'history',
  routes: [{
      path: '/',
      meta: {
        gest: false
      },
      component: function() {
        return import('./components/NavBar.vue')
      },
      children: [{
          path: '/Home',
          name: 'Home',
          meta: {
            helpUrl: "",
            title: "Dashboard",
            requiresAuth: true
          },
          component: function() {
            return import('./views/Home.vue')
          }
        }, {
          path: '/Members',
          name: 'Members',
          meta: {
            helpUrl: "",
            title: "Mitgliederliste",
            requiresAuth: true
          },
          component: function() {
            return import('./views/Members.vue')
          }
        }, {
          path: '/Companies',
          name: 'Companies',
          props: true,
          meta: {
            helpUrl: "",
            title: "Firmenprofil",
            requiresAuth: true
          },
          component: function() {
            return import('./views/Companies.vue')
          }
        },
        {
          path: '/Events',
          name: 'Events',
          props: true,
          meta: {
            helpUrl: "",
            title: "Veranstaltungen",
            requiresAuth: true
          },
          component: function() {
            return import('./views/Events.vue')
          }
        }, {
          path: '/Users',
          name: 'Users',
          meta: {
            helpUrl: "",
            title: "Benutzerkonten",
            requiresAuth: true
          },
          component: function() {
            return import('./views/Users.vue')
          }
        },
        {
          path: '/PoisUploader',
          name: 'PoisUploader',
          meta: {
            helpUrl: "",
            title: "POIs hochladen",
            requiresAuth: true,
            isAdmin: true
          },
          component: function() {
            return import('./views/PoisUploader.vue')
          }
        },
        {
          path: '/CompanyDetails/:id',
          name: 'CompanyDetails',
          meta: {
            // helpUrl: "",
            title: "Firmenprofil",
            requiresAuth: true
          },
          props: true,
          component: function() {
            return import('./views/CompanyDetails.vue')
          }
        },{
          path: '/MemberDetails/:id',
          name: 'MemberDetails',
          meta: {
            // helpUrl: "",
            title: "Mitgliederliste",
            requiresAuth: true
          },
          props: true,
          component: function() {
            return import('./views/MemberDetails.vue')
          }
        },
        {
          path: '/CompanyWizard/:id',
          name: 'CompanyWizard',
          meta: {
            // helpUrl: "",
            title: "Firmenprofil",
            requiresAuth: true
          },
          props: true,
          component: function() {
            return import('./views/CompanyWizard.vue')
          }
        },
        {
          path: '/MemberWizard/:id',
          name: 'MemberWizard',
          meta: {
            // helpUrl: "",
            title: "Mitgliederliste",
            requiresAuth: true
          },
          props: true,
          component: function() {
            return import('./views/MemberWizard.vue')
          }
        },
        {
          path: '/EventWizard/:id',
          name: 'EventWizard',
          meta: {
            // helpUrl: "",
            title: "Veranstaltungen",
            requiresAuth: true,
            isEventUser: false
          },
          props: true,
          component: function() {
            return import('./views/EventWizard.vue')
          }
        }
      ]
    },
    {
      path: '/login',
      name: 'Login',
      meta: {
        gest: true
      },
      component: function() {
        return import('./views/Login.vue')
      }
    },
    {
      path: '/PasswordReset',
      name: 'Password Reset',
      meta: {
        gest: true
      },
      component: function() {
        return import('./views/PasswordReset.vue')
      }
    },
    {
      path: '/EmailVerified',
      name: 'EmailVerified',
      meta: {
        gest: true
      },
      component: function() {
        return import('./views/EmailVerified.vue')
      }
    },
    {
      path: '/EmailVerified/Download',
      name: 'EmailVerifiedDownload',
      meta: {
        gest: true
      },
      component: function() {
        return import('./views/EmailVerifiedDownload.vue')
      }
    },
    {
      path: '/event-details',
      name: 'EventDetails',
      meta: {
        gest: true
      },
      component: function() {
        return import('./views/EventDetails.vue')
      }
    }
  ]
})
