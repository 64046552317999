import axios from 'axios';
import {
  EventBus
} from './event-bus.js';
import {
  APIService
} from './api-service.js';
import Router from './router';

const apiService = new APIService('BackendUsers');

export class SecurityService {

  static _instance;

  constructor() {
    if (this._instance) {
      return this._instance;
    }
    this._instance = this;
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('currentUser'));
  }

  isCurrentAdmin() {
    return this.getCurrentUser().userRole == "wroAdmin";
  }

  isCurrentOwner(companies) {
    let userCompanies = this.getCurrentUser().companies;
    for (let company of companies) {
      if (userCompanies.includes(company)) {
        return true;
      }
    }
    return false;
  }

  refreshCurrentUserCompanies() {
    apiService.getOne(this.getCurrentUser().id).then(resp => {
      localStorage.setItem('currentUser', JSON.stringify(resp));
    }).catch(function(error) {
      console.error(error.response);
    });
  }

  login(username, password) {
    var promise = apiService.login(username, password);
    promise.then(response => {
        localStorage.setItem('jwt', response.data.id);
        apiService.getOne(response.data.userId).then(resp => {
          localStorage.setItem('currentUser', JSON.stringify(resp));
          EventBus.$emit('update-badges');
          if (localStorage.getItem('jwt') != null) {
            // if(this.$route.params.nextUrl != null){
            //     Router.push(this.$route.params.nextUrl);
            // }
            // else {
            Router.push('/Home');
            // }
          }
        }).catch(function(error) {
          localStorage.removeItem("jwt");
          localStorage.removeItem("currentUser");
          console.error(error.response);

        });
      })
      .catch(function(error) {
        console.error(error.response);
      });

    return promise;
  }

  logout() {
    apiService.logout().then(response => {
        console.log(response);
        this.deleteSession();
      })
      .catch(function(error) {
        console.error(error.response);
      });
  }

  deleteSession() {
    localStorage.removeItem("jwt");
    localStorage.removeItem("currentUser");
    Router.push("/Login");
  }
}
